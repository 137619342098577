<template>
  <v-card>
    <v-card-title
      class="headline msaBlue white--text text-h6 pa-2 font-weight-bold"
    >
      Logo
    </v-card-title>
    <v-card-text class="pt-4">
      <v-row>
        <v-col align="center">
          <v-img
            :key="reloadKey"
            v-show="!loading"
            v-if="hasLogo"
            contain
            width="55%"
            aspect-ratio="1"
            class="pt-5"
            :src="url"
            @loadstart="loading = true"
            @load="loading = false"
            @error="
              loading = false;
              logoNotFound = true;
              placeholderText = 'Logo not found.';
            "
          >
          </v-img>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="msaBlue"
          ></v-progress-circular>
          <div v-if="!loading && (!hasLogo || logoNotFound)">
            {{ placeholderText }}
          </div>
        </v-col>
      </v-row>
      <v-row :key="reloadUpload">
        <v-col class="text-right">
          <v-btn
            text
            class="red--text font-weight-bold"
            @click="deleteLogo()"
            :disabled="!hasLogo"
          >
            Delete
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            class="msaBlue white--text"
            @click="$refs.uploader.click()"
            :disabled="$store.getters.selectedCompany.id == -1"
          >
            Upload
          </v-btn>
          <input
            type="file"
            ref="uploader"
            class="d-none"
            accept=".gif,.jpg,.jpeg,.png"
            @change="uploadLogo"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
export default {
  name: 'CompanyLogo',
  props: {
    hasLogo: {
      type: Boolean,
    },
  },
  watch: {
    hasLogo(val) {
      if (val) {
        this.getLogo();
      } else {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      reloadKey: 1,
      url: '',
      loading: true,
      placeholderText: '',
      logoNotFound: undefined,
      reloadUpload: this.$helpers.createGuid(),
    };
  },
  methods: {
    getLogo() {
      const params = {
        jwtToken: this.$store.getters.jwtToken,
      };

      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      const promise = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      });
      promise
        .post('download-organization-logo?format=json', params, {
          withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
        })
        .then((response) => {
          if (response.data != '') {
            this.url = 'data:image/jpeg;base64,' + response.data;
          } else {
            if (this.hasLogo) {
              this.logoNotFound = true;
              this.placeholderText = 'Logo not found.';
            } else {
              this.placeholderText = 'Logo not set.';
              this.logoNotFound = undefined;
            }
          }
        });
    },
    async uploadLogo(e) {
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      this.loading = true;
      this.$root.toggleLoaderText(true);
      try {
        file = await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.IMAGE,
          true,
        );
      } catch (error) {
        this.$root.toggleLoaderText(false);
        this.loading = false;
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }
      this.$root.toggleLoaderText(false);

      const url = 'upload-organization-logo';

      const params = new FormData();
      params.append('file', file);
      params.append('fileName', file.name);

      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Uploading...');

      if (this.$store.getters.isInternalAdmin) {
        params.append('companyId', this.$store.getters.selectedCompany.id);
      }

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      this.$axios
        .post(url, params, headers)
        .then(() => {
          this.getLogo();
          this.loading = false;
          this.placeholderText = '';
          this.logoNotFound = undefined;
          if (!this.hasLogo) {
            this.$emit('toggleHasLogo');
          }
          if (this.$store.getters.isWebAdmin) {
            this.$root.reloadLogo();
          }
          this.reloadUpload = this.$helpers.createGuid();
        })
        .catch(() => (this.loading = false));
    },
    deleteLogo() {
      let params = {
        id: this.$store.getters.selectedCompany.id,
        loaderText: 'Deleting...',
      };

      if (this.$store.getters.isInternalAdmin) {
        params.id = this.$store.getters.selectedCompany.id;
      }

      let url = 'delete-organization-logo?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('toggleHasLogo');
          this.url = '';
          this.loading = false;
          if (this.$store.getters.isWebAdmin) {
            this.$root.reloadLogo();
          }
        })
        .catch((error) => error);
    },
  },
  mounted() {
    if (this.hasLogo) {
      this.getLogo();
    } else {
      this.loading = false;
      this.placeholderText = 'Logo not set.';
    }
  },
};
</script>
